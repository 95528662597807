import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import { Textfit } from 'react-textfit';

import { black, slate, white, granite } from 'shared/colors';
import { Header, Font, Text } from 'components/Text/Text';
import { BREAKPOINTS } from 'shared/constants';
import SEO from 'components/SEO/SEO';

interface Props {
  data: {
    contentful404NotFoundPage: {
      notFoundText: string;
      buttonText: string;
    };
  };
  location: {
    pathname: string;
  };
}

const FourOhFour: React.FC<Props> = ({
  data,
  location: { pathname },
}: Props) => {
  const { notFoundText, buttonText } = data.contentful404NotFoundPage;

  // Effect to fire resize event, forcing textfit to recalculate font size
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  });

  return (
    <main className={css(styles.container)}>
      <SEO title="Page not found" pathname={pathname} />
      <Textfit
        className={css(styles.text)}
        mode="single"
        min={10}
        max={500}
        style={{ width: '99vw' }}
      >
        <Header
          text={notFoundText}
          font={Font.TitlingGothicFBCompressedBlack}
          extraStyle={styles.text}
        />
      </Textfit>
      <Link to="/" className={css(styles.button)}>
        {' '}
        <Text
          font={Font.TitlingGothicFBCompressedRegular}
          extraStyle={styles.buttonText}
          text={buttonText}
        />
      </Link>
    </main>
  );
};

export default FourOhFour;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    backgroundColor: black,
    color: slate,
    minHeight: '100vh',
    width: '100%',
  },
  text: {
    fontSize: 'auto',
    paddingTop: '22vh',
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '15vh',
    },
  },
  button: {
    display: 'flex',
    alignContent: 'center',
    backgroundColor: granite,
    height: 70,
    color: slate,
    marginTop: 100,
    alignSelf: 'center',
    padding: '5px 30px',
    textDecoration: 'none',
  },
  buttonText: {
    alignSelf: 'center',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 1.4,
    color: white,
    textTransform: 'uppercase',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
    },
  },
});

export const query = graphql`
  query NotFoundPageQuery {
    contentful404NotFoundPage {
      buttonText
      notFoundText
    }
  }
`;
